<template>
  <div class="font-size-24">
    <img src="/resources/images/403.svg" class="width-400" alt="404" />
    <div class="font-weight-bold font-size-60 mb-3">No autorizado</div>
    <div class="mb-3">No tenés permiso para realizar esta acción. En caso que necesites ayuda podés escribirnos a nuestro <a href="mailto:hola@jipink.com">mail</a>, o directamente contactar a nuestro soporte.</div>
    <router-link to="/" class="btn btn-outline-primary width-100">Ir a la Home</router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
